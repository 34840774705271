import { IItem } from '@pickles/shared/models';
import { Account } from '@pickles/shared/models/user.types';
import { trackItemView } from '@pickles/shared/utils/analytics';
import { v4 as uuidv4 } from 'uuid';

export const trackItemWebView = (item: IItem.Item, user: Account | null) => {
    let publicUserId: string | null = localStorage.getItem('public_user_id');
    if (!publicUserId) {
        publicUserId = uuidv4();
        localStorage.setItem('public_user_id', publicUserId);
    }

    trackItemView(item, user, publicUserId);
};
